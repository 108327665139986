import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout.jsx";
import SEO from "../components/seo.jsx";
import styles from "./../styles/modules/project.module.scss";

const BlogArticle = ({ data }) => {
  const article = data.markdownRemark.frontmatter;
  const articleHtml = { __html: data.markdownRemark.html };

  return (
    <Layout header="none">
      <SEO
        title="Work"
        keywords={[
          `web`,
          `developer`,
          "frontend",
          "front end",
          "front-end",
          "ux",
          "designer",
          "javascript developer",
          "react"
        ]}
      />
      <main className={styles.main}>
        <div className={styles.content} dangerouslySetInnerHTML={articleHtml} />
      </main>
    </Layout>
  );
};

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
      }
    }
  }
`;

export default BlogArticle;
